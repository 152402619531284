export const en = {
  "auth": {
    "email": "Email",
    "enterEmail": "Enter your email",
    "enterName": "Enter your name",
    "enterPassword": "Enter your password",
    "enterSurname": "Enter your surname",
    "forgotPassword": "Forgot password?",
    "name": "Name",
    "password": "Password",
    "signIn": "Sign In",
    "signUp": "Sign Up",
    "surname": "Surname"
  },
  "bots": {
    "bots": "Bots",
    "botsTable": "Bots table",
    "createBot": "Create bot",
    "deleteBot": "Delete bot",
    "editBot": "Edit bot",
    "table": {
      "createdAt": "Created at",
      "id": "Id",
      "identifier": "Identifier",
      "name": "Name",
      "type": "Type",
      "updatedAt": "Updated at",
      "username": "Username"
    },
    "viewBot": "View bot"
  },
  "buttons": {
    "apply": "Apply",
    "cancel": "Cancel",
    "create": "Create",
    "delete": "Delete",
    "edit": "Edit",
    "next": "Next",
    "search": "Search",
    "select": "Select"
  },
  "channels": {
    "addNewButton": "Add new button",
    "buttonText": "Button text",
    "buttonUrl": "Button url",
    "channels": "Channels",
    "channelsTable": "Channels table",
    "createBotAnswer": "Create Bot Answer",
    "createChannel": "Create channel",
    "createPush": "Create push",
    "deleteChannel": "Delete channel",
    "deletePush": "Delete push",
    "editChannel": "Edit channel",
    "enterAfterTime": "Enter After Time",
    "enterMessage": "Enter Message",
    "enterText": "Enter Text",
    "enterUrl": "Enter Url",
    "selectButtonType": "Select Button Type",
    "selectSourceType": "Select Source Type",
    "selectTemplateId": "Select Template ID",
    "selectType": "Select Type",
    "table": {
      "afterTime": "After time",
      "createdAt": "Created at",
      "id": "Id",
      "identifier": "Identifier",
      "isActive": "Is Active",
      "message": "Message",
      "messageAt": "Message at",
      "name": "Name",
      "sourceId": "Source ID",
      "sourceType": "Source Type",
      "templateId": "Template ID",
      "type": "Type",
      "updatedAt": "Updated at"
    },
    "updatePush": "Update push",
    "viewChannel": "View channel"
  },
  "dtos": {
    "link_one": "Link",
    "link_other": "Links"
  },
  "fields": {
    "channel_one": "Channel",
    "channel_other": "Channels"
  },
  "footer": {
    "allRightsReserved": "All rights reserved"
  },
  "forms": {
    "botId": "Bot Id",
    "buyer": "Buyer",
    "channelId": "Channel Id",
    "enterBuyer": "Enter Buyer",
    "enterFbPixel": "Enter fbPixel",
    "enterFbToken": "Enter fbToken",
    "enterSource": "Enter Source",
    "fbPixel": "fbPixel",
    "fbToken": "fbToken",
    "selectBotId": "Select BotId",
    "selectChannelId": "Select Channel Id",
    "source": "Source"
  },
  "general": {
    "administrator": "Administrator",
    "administrators": "Administrators",
    "approveTo": "Do you want to {{ phrase }}?",
    "createAdministrator": "Create Administrator",
    "editAdministrator": "Edit Administrator",
    "filters": "Filters",
    "home": "Home",
    "pageNotFound": "Page not found",
    "personalData": "Personal Data",
    "table": {
      "dtoTable": "{{ dtoName }} table",
      "noData": "No Data",
      "pagination": "Showing {{currCountOfRows}} to {{toCountOfRows}} of {{total}} entries"
    },
    "viewAdministrator": "View Administrator"
  },
  "links": {
    "contactDeveloper": "Contact Developer",
    "createBotsLink": "Create Bot's Link",
    "createChannelsLink": "Create Channel's Link",
    "links": "Links",
    "support": "Support"
  },
  "profile": {
    "hey": "Hey, {{name}}",
    "logOut": "Log out"
  },
  "subscribers": {
    "createSubscriber": "Create subscriber",
    "editSubscriber": "Edit subscriber",
    "subscribers": "Subscribers",
    "subscribersTable": "Subscribers table",
    "table": {
      "bot": "Bot",
      "buyer": "Buyer",
      "createdAt": "Created at",
      "id": "Id",
      "identifier": "Identifier",
      "messages": "Messages",
      "name": "Name",
      "resource": "Resource",
      "resources": "Resources",
      "source": "Source",
      "updatedAt": "Updated at",
      "username": "Username"
    },
    "viewSubscriber": "View subscriber"
  },
  "subscriptions": {
    "createSubscription": "Create subscription",
    "editSubscription": "Edit subscription",
    "subscriptions": "Subscriptions",
    "subscriptionsTable": "Subscriptions table",
    "table": {
      "buyer": "Buyer",
      "createdAt": "Created at",
      "id": "Id",
      "identifier": "Identifier",
      "name": "Name",
      "resource": "Resource",
      "source": "Source",
      "updatedAt": "Updated at",
      "url": "Url",
      "username": "Username"
    },
    "viewSubscription": "View subscription"
  },
  "validation": {
    "email": "Please enter a valid email address",
    "required": "{{field}} is a required field"
  },
  "yes": "Yes",
  "no": "No"
};
