import { Button, Center, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// import { useConfirm, useResendOtpCode } from 'shared/api/requests/auth';
import TextField from 'shared/components/form/TextField';
import ShadowCard from 'shared/components/ShadowCard';
import SubmitButton from 'shared/components/SubmitButton';
import { TwoFactorFormT, twoFactorResolver, twoFactorValues } from 'shared/form-values/auth';
import useGetColors from 'shared/hooks/useGetColors';
import { OTP_CODE_EXPIRATION_TIME_COOKIE, SESSION_ID } from 'shared/utils/constants';
import { fillFormErrors, getCookies, setCookies } from 'shared/utils/devUtils';
import { formatSecsToMinsAndSecs } from 'shared/utils/formattings';

const TwoFactor = () => {
  const sessionId = getCookies(SESSION_ID);
  const { gray500, brand500 } = useGetColors();
  const otpCodeExpirationTimeFromCookie = getCookies(OTP_CODE_EXPIRATION_TIME_COOKIE) as number;

  const formatToResendTime = (expirationTime: number) => {
    const otpCodeExpirationTime = expirationTime - Date.now();
    return Math.round(otpCodeExpirationTime / 1000);
  }
  
  const [resendTime, setResendTime] = useState(formatToResendTime(otpCodeExpirationTimeFromCookie));
  const [timerId, setTimerId] = useState(null);
  // const { mutate, isPending } = useConfirm();
  // const { mutate: resendOtpCode, isPending: isPendingResendOtpCode } = useResendOtpCode();

  const startTimer = () => {
    setTimerId(setInterval(() => {
      setResendTime(prevState => prevState - 1);
    }, 1000));
  };

  useEffect(() => {
    startTimer();
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (resendTime > 1) return;
    return () => clearInterval(timerId);
  }, [resendTime]);

  useEffect(() => {
    methods.trigger();
  }, []);

  const methods = useForm<TwoFactorFormT>({
    defaultValues: twoFactorValues,
    resolver: twoFactorResolver,
    mode: "onChange"
  });

  // const onSubmit: SubmitHandler<TwoFactorFormT> = (values) => (
  //   mutate({ ...values, sessionId }, { onError: (error) => fillFormErrors(methods.setError, error) })
  // );

  // const handleResendOtpCode = () => {
  //   resendOtpCode({ sessionId }, {
  //     onSuccess: (data) => {
  //       clearInterval(timerId)
  //       setCookies(SESSION_ID, data.id);
  //       setResendTime(data.expirationTime)
  //       startTimer();
  //     }
  //   });
  // };

  return (
    <ShadowCard titleText="2FA Verification">
      <FormProvider {...methods}>
      {/*<form onSubmit={methods.handleSubmit(onSubmit)}>*/}
      {/*  <Text color={gray500} fontSize="sm" mb="20px">*/}
      {/*    Please enter the OTP (one time password) to authenticate. The code was sent to email.*/}
      {/*  </Text>*/}
      {/*  <TextField<TwoFactorFormT>*/}
      {/*    name="otpCode"*/}
      {/*    label="2FA code"*/}
      {/*    placeholder="Enter code"*/}
      {/*    mb="24px"*/}
      {/*  />*/}
      {/*  <Center>*/}
      {/*    {resendTime > 0 ? (*/}
      {/*      <Text color={gray500} fontSize="15px">*/}
      {/*        Resend verification code in {formatSecsToMinsAndSecs(resendTime)}*/}
      {/*      </Text>*/}
      {/*    ) : (*/}
      {/*      <Button*/}
      {/*        color={brand500}*/}
      {/*        variant="link"*/}
      {/*        type="button"*/}
      {/*        fontSize="15px"*/}
      {/*        fontWeight="500"*/}
      {/*        isLoading={isPending || isPendingResendOtpCode}*/}
      {/*        onClick={handleResendOtpCode}*/}
      {/*      >*/}
      {/*        Resend verification code*/}
      {/*      </Button>*/}
      {/*    )}*/}
      {/*  </Center>*/}
      {/*  <SubmitButton mt="24px" label="Login" isLoading={isPending} />*/}
      {/*</form>*/}
      </FormProvider>
    </ShadowCard>
  );
};

export default TwoFactor;
