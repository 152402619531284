import { Box, Table as ChakraTable } from '@chakra-ui/react';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import TableHead from './TableHead';

interface Props<TData> {
  columns: ColumnDef<TData>[];
  data: TData[],
}

const Table = <TData extends UnknownRecord>({
  data,
  columns,
}: Props<TData>) => {
  const table = useReactTable<TData>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
    meta: {},
  });

  return (
    <div>
      <Box overflowX="auto">
        <ChakraTable
          size="sm"
          w="100%"
          style={{ tableLayout: 'fixed' }}
          minWidth="max-content"
          width="-webkit-fill-available"
        >
          <TableHead<TData> table={table} disabledSort />
          <TableBody<TData> table={table} isLoading={false} data={data} />
          <TableFooter<TData> table={table} />
        </ChakraTable>
      </Box>
    </div>
  );
};

export default Table;
