import {
  Box, Grid, Image, Link,
  List,
  ListItem,
  Text
} from '@chakra-ui/react';
import AppLogo from 'components/AppLogo';
import { useTranslation } from 'react-i18next';
import useGetColors from 'shared/hooks/useGetColors';
import { APP_VERSION, CONTACT_DEVELOPER_URL, SUPPORT_URL } from 'shared/utils/constants';

interface Props {
  isHideLogo?: boolean
}

const FooterAuth = ({ isHideLogo }: Props) => {
  const { t } = useTranslation();
  const { textColor } = useGetColors();
  return (
    <Grid
      alignItems="end"
      justifyContent="space-between"
      px='30px'
      pb="10px"
      gridTemplateColumns={{
        'lg': isHideLogo ? "1fr 1fr" : "1fr 1fr 1fr",
        'base': "1fr",
      }}
      gridTemplateAreas={{
        'lg': "'copyright logo links'",
        'base': "'logo' 'links' 'copyright'"
      }}
      gridGap="10px"
    >
      <Text
        gridArea="copyright"
        as="span"
        fontWeight="500"
        ms="4px"
        color="secondaryGray.600"
        fontSize="small"
        width="100%"
      >
        Copyright © 2015-{new Date().getFullYear()} slicky. {t('footer.allRightsReserved')}.
      </Text>
      {!isHideLogo && (
        <Box textAlign="center" width="100%" gridArea="logo">
          <Text as="span" fontWeight="500" color={textColor} fontSize="x-small">
            Powered by
          </Text>
        </Box>
      )}
      {/* <Box width="100%">
        <List
          display="flex"
          alignItems="center"
          justifyContent="end"
          width="100%"
          gridArea="links"
          maxWidth="360px"
          marginLeft="auto"
        >
          <ListItem mr="5px">
            <Link
              fontSize="small"
              fontWeight="500"
              color={textColor}
              href={SUPPORT_URL}
              textDecoration="underline"
            >
              {t('links.support')}
            </Link>
          </ListItem>
          <ListItem mr="5px">
            <Link
              fontSize="small"
              fontWeight="500"
              color={textColor}
              textDecoration="underline"
              href={CONTACT_DEVELOPER_URL}
            >
              {t('links.contactDeveloper')}
            </Link>
          </ListItem>
          <ListItem mr="5px">
            <Text fontWeight="500" fontSize="small" color="secondaryGray.600">
              Slicky Panel v{APP_VERSION}
            </Text>
          </ListItem>
        </List>
      </Box> */}
    </Grid>
  );
};

export default FooterAuth;
