import { Box, Button, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/horizon-ui/card/Card';
import { HSeparator } from 'shared/components/horizon-ui/separator/Separator';
import Link from 'shared/components/Link';
import useGetColors from 'shared/hooks/useGetColors';
import { PathsE } from 'shared/routes';
import { fillFormErrors } from 'shared/utils/devUtils';
import PageTitle from '../admin/PageTitle';
import BotsLinkForm from './BotsLinkForm';
import {CreateBotsLinkFormT, createBotsLinkResolver, getCreateBotsLinkValues} from '../../form-values/links';
import {useCreateBotsLink} from '../../api/requests/links';

const CreateBotsLink = () => {
  const { t } = useTranslation();
  const { mutate, isPending } = useCreateBotsLink();
  const { gray800, gray700, white, gray100, brand500, brand400 } = useGetColors();
  const methods = useForm<CreateBotsLinkFormT>({
    defaultValues: getCreateBotsLinkValues(),
    resolver: createBotsLinkResolver,
    mode: "onChange"
  });
  const { formState: { isValid }, trigger } = methods;

  useEffect(() => {
    trigger();
  }, []);

  const onSubmit: SubmitHandler<CreateBotsLinkFormT> = (values) => mutate(values, { onError: (error) => fillFormErrors(methods.setError, error) });

  const breadcrumbs = [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
    {
      path: PathsE.LINKS,
      text: t('links.links'),
    },
  ]

  return (
    <Box>
      <PageTitle title={t('links.createBotsLink')} breadcrumbs={breadcrumbs} backUrl={{
        path: PathsE.LINKS
      }} />
      <Card mb="20px" borderRadius="20px" px="30px">
        <Text color={gray700} fontWeight="600">
          {t('general.personalData')}
        </Text>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <BotsLinkForm />
            <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
            <Link path={PathsE.LINKS}>
              <Button
                color={gray800}
                bgColor={gray100}
                borderRadius="8px"
                w="100px"
              >
                {t('buttons.cancel')}
              </Button>
            </Link>
            <Button
              color={white}
              borderRadius="8px"
              _hover={{
                bgColor: brand400,
              }}
              bgColor={brand500}
              ml="20px"
              isLoading={isPending}
              w="100px"
              type="submit"
              isDisabled={!isValid}
            >
              {t('buttons.create')}
            </Button>
          </form>
        </FormProvider>
      </Card>
    </Box>
  );
};

export default CreateBotsLink;
