import { Box, Button, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetOneBot, useUpdateOneBot } from 'shared/api/requests/bots';
import Card from 'shared/components/horizon-ui/card/Card';
import { HSeparator } from 'shared/components/horizon-ui/separator/Separator';
import Link from 'shared/components/Link';
import Loader from 'shared/components/Loader';
import {
  getUpdateBotValues,
  UpdateBotFormT,
  updateBotResolver
} from 'shared/form-values/bots';
import useGetColors from 'shared/hooks/useGetColors';
import { PathsE, PathsParams } from 'shared/routes';
import { fillFormErrors } from 'shared/utils/devUtils';
import PageTitle from '../admin/PageTitle';
import BotForm from './BotForm';

const Update = () => {
  const { t } = useTranslation();
  const { botId } = useParams<PathsParams[PathsE.UPDATE_BOT]>();
  const { mutate, isPending: isLoadingUpdate } = useUpdateOneBot({
    pathParams: { botId },
    params: undefined,
  });
  const { data, isLoading } = useGetOneBot({
    pathParams: { botId },
    params: undefined,
  });

  const { gray800, gray700, white, gray100, brand500, brand400 } = useGetColors();
  
  const methods = useForm<UpdateBotFormT>({
    defaultValues: getUpdateBotValues(data),
    resolver: updateBotResolver,
    mode: "onChange",
  });
  const { formState: { isValid, defaultValues }, trigger } = methods;

  useEffect(() => {
    trigger();
  }, []);
  
  useEffect(() => {
    methods.reset(data);
  }, [data])

  const onSubmit: SubmitHandler<UpdateBotFormT> = (values) => {
    mutate(values, { onError: (error) => fillFormErrors(methods.setError, error) })
  };

  const breadcrumbs = [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
    {
      path: PathsE.BOTS,
      text: t('bots.bots'),
    },
    {
      path: PathsE.SHOW_BOT,
      text: t('bots.viewBot'),
      pathsParams: {
        botId: data?.id,
      },
    },
  ];

  if (isLoading) return <Loader />;

  return (
    <Box>
      <PageTitle
        title={t('bots.editBot')}
        breadcrumbs={breadcrumbs}
        backUrl={{
          path: PathsE.SHOW_BOT,
          pathsParams: {
            botId: data?.id,
          }
        }}
      />
      <Card mb="20px" borderRadius="20px" px="30px">
        <Text color={gray700} fontWeight="600">
          {t('general.personalData')}
        </Text>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <BotForm />
            <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
            <Link<PathsParams[PathsE.SHOW_BOT]> path={PathsE.SHOW_BOT} pathsParams={{ botId }}>
              <Button
                color={gray800}
                bgColor={gray100}
                borderRadius="8px"
                w="100px"
              >
                {t('buttons.cancel')}
              </Button>
            </Link>
            <Button
              color={white}
              _hover={{
                bgColor: brand400,
              }}
              bgColor={brand500}
              borderRadius="8px"
              ml="20px"
              isLoading={isLoadingUpdate}
              w="100px"
              type="submit"
              isDisabled={!isValid}
            >
              {t('buttons.edit')}
            </Button>
          </form>
        </FormProvider>
      </Card>
    </Box>
  );
};

export default Update;
