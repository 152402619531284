import { Checkbox, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import {
  ErrorMessage,
  FieldValuesFromFieldErrors
} from '@hookform/error-message';
import {
  FieldErrors,
  FieldName,
  Path,
  useFormContext,
} from 'react-hook-form';
import useGetColors from 'shared/hooks/useGetColors';

interface Props<F extends UnknownRecord> {
  name: Path<F>;
  label?: string;
}

const CheckboxField = <F extends UnknownRecord>({
  label,
  name,
}: Props<F>) => {
  const { gray800 } = useGetColors();
  const {
    register,
    formState: { errors, touchedFields },
  } = useFormContext<F>();
  const isInvalid = !!errors[name] && !!touchedFields[name];

  return (
    <FormControl isInvalid={isInvalid} display="flex" alignItems="center" position="relative">
      <Checkbox
        {...register(name)}
        id="remember-login"
        colorScheme="brandScheme"
        me="10px"
      />
      <FormLabel
        htmlFor="remember-login"
        mb="0"
        fontWeight={500}
        color={gray800}
        fontSize="md"
      >
        {label}
      </FormLabel>
      {isInvalid && (
        <FormErrorMessage>
          <ErrorMessage
            errors={errors}
            name={
              (name as unknown) as FieldName<
                FieldValuesFromFieldErrors<FieldErrors<F>>
              >
            }
          />
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default CheckboxField;
