import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import useGetColors from 'shared/hooks/useGetColors';
import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  CreateBotAnswerFormT, createBotAnswerResolver, getCreateBotAnswerValues,
} from 'shared/form-values/channels'
import { BotAnswerT, TemplateOptionT } from 'shared/api/types/channels'
import BotAnswerForm from './BotAnswerForm'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string;
  isOpen: boolean;
  onClose: VoidFunction;
  botAnswer: BotAnswerT;
  onSubmit: (data: CreateBotAnswerFormT) => void;
  templateOptions: TemplateOptionT[];
}

const BotAnswerModal = ({ title, isOpen, onClose, onSubmit, botAnswer, templateOptions }: Props) => {
  const { t } = useTranslation();
  const { gray800, white, gray100, brand500, brand400 } = useGetColors();

  const methods = useForm<CreateBotAnswerFormT>({
    defaultValues: getCreateBotAnswerValues(botAnswer),
    resolver: createBotAnswerResolver,
    mode: "onChange"
  });
  const { trigger, getValues } = methods;

  useEffect(() => {
    trigger();
  }, []);

  useEffect(() => {
    methods.reset(getCreateBotAnswerValues(botAnswer));
  }, [botAnswer, isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <BotAnswerForm templateOptions={templateOptions} />
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Button
            color={gray800}
            bgColor={gray100}
            borderRadius="8px"
            onClick={onClose}
            w="100px"
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            color={white}
            bgColor={gray100}
            borderRadius="8px"
            backgroundColor={brand500}
            _hover={{
              background: brand400,
            }}
            ml="20px"
            w="100px"
            type="submit"
            onClick={() => onSubmit(getValues())}
          >
            {t('buttons.create')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BotAnswerModal;
