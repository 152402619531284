import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import useGetColors from 'shared/hooks/useGetColors';
import TemplateForm from './TemplateForm';
import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CreateTemplateFormT, createTemplateResolver, getCreateChannelTemplateValues } from 'shared/form-values/channels';
import { TemplateT } from 'shared/api/types/channels';
import { useTranslation } from 'react-i18next'

interface Props {
  title: string;
  isOpen: boolean;
  onClose: VoidFunction;
  template: TemplateT;
  onSubmit: (data: CreateTemplateFormT) => void;
}

const TemplateModal = ({ title, isOpen, onClose, onSubmit, template }: Props) => {
  const { gray800, white, gray100, brand500, brand400 } = useGetColors();
  const { t } = useTranslation();

  const methods = useForm<CreateTemplateFormT>({
    defaultValues: getCreateChannelTemplateValues(template),
    resolver: createTemplateResolver,
    mode: "onChange"
  });
  const { trigger, getValues } = methods;

  useEffect(() => {
    trigger();
  }, []);

  useEffect(() => {
    methods.reset(getCreateChannelTemplateValues(template));
  }, [template, isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <TemplateForm />
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Button
            color={gray800}
            bgColor={gray100}
            borderRadius="8px"
            onClick={onClose}
            w="100px"
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            color={white}
            bgColor={gray100}
            borderRadius="8px"
            backgroundColor={brand500}
            _hover={{
              background: brand400,
            }}
            ml="20px"
            w="100px"
            type="submit"
            onClick={() => onSubmit(getValues())}
          >
            {t('buttons.create')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TemplateModal;
