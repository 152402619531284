import { AddIcon } from "@chakra-ui/icons";
import { Tag, TagLeftIcon, TagLabel, Text, Flex, Box } from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import MatIcon from "components/MatIcon";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetBots } from "shared/api/requests/bots";
import { useGetChannels } from "shared/api/requests/channels";
import { SubscribersElementT } from "shared/api/types/subscribers";
import CutText from "shared/components/CutText";
import MainItemMenu from "shared/components/MainItemMenu";
import { PathsE } from "shared/routes";
import { FilterField } from "shared/types/filters";
import { formatDateToHuman } from "shared/utils/formattings";

export const useGetFilterFields = (): FilterField[] => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      type: 'async-select',
      isMulti: true,
      name: 'channelIds',
      defaultValue: [],
      placeholder: 'Select channels',
      optionsQuery: useGetChannels as FixMeLater,
      label: t('fields.channel', { count: 0 }),
      size: "lg",
      labelName: 'name',
      valueName: 'id',
    },
    {
      type: 'async-select',
      isMulti: true,
      name: 'botIds',
      defaultValue: [],
      placeholder: 'Select bots',
      optionsQuery: useGetBots as FixMeLater,
      label: t('bots.bots'),
      size: "lg",
      labelName: 'name',
      valueName: 'id',
    },
    {
      type: 'date-range',
      name: 'messageAt',
      defaultValue: [],
      label: t('channels.table.messageAt'),
      size: "lg"
    },
    {
      type: 'date-range',
      name: 'createdAt',
      defaultValue: [],
      label: t('subscribers.table.createdAt'),
      size: "lg"
    },
  ], [t]);
}

export const useGetGetBreadcrumbs = () => {
  const { t } = useTranslation();
  return [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
  ];
}


export const useGetColumns = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<SubscribersElementT>();

  return useMemo(
    () => [
      columnHelper.accessor('id', {
        header: t('subscribers.table.id'),
        id: 'id',
        cell: ({ getValue }) => (
          <CutText fontWeight="700">
            {getValue()}
          </CutText>
        ),
        meta: {
          isSorting: false,
          styles: {
            sizes: {
              width: '90px',
              maxW: '90px',
            }
          }
        }
      }),
      columnHelper.accessor('identifier', {
        header: t('subscribers.table.identifier'),
        id: 'identifier',
      }),
      columnHelper.accessor('name', {
        header: t('subscribers.table.name'),
        id: 'name',
      }),
      // columnHelper.accessor('firstName', {
      //   // TODO
      //   header: 'DEPARTMENT',
      //   id: 'department',
      //   meta: {
      //   }
      // }),
      columnHelper.accessor('username', {
        header: t('subscribers.table.username'),
        id: 'username',
      }),
      columnHelper.accessor('bot.name', {
        header: t('subscribers.table.bot'),
        id: 'bot',
      }),
      columnHelper.accessor('resources', {
        cell: ({ getValue }) => {
          const countOfItems = getValue().length;
          const firstItem = getValue()[0];
          if (!firstItem) return null;
          return (
            <Flex alignItems="center" justifyContent="space-between" gap={2}>
              <Box mr="4px">
                <MatIcon
                  name={firstItem.type === 'bot' ? 'smart_toy' : 'group'}
                  color={firstItem.type === 'bot' ? 'red' : '#5140E7'}
                />
              </Box>
              <Text as="span" mr="4px">{firstItem.name}</Text>
              {countOfItems > 1 && (
                <Tag size="md" variant='subtle' colorScheme='brand'>
                  <TagLabel>+{countOfItems - 1}</TagLabel>
                </Tag>
              )}
            </Flex>
          )
        },
        header: t('subscribers.table.resources'),
        id: 'resources',
      }),
      columnHelper.accessor('resources', {
        cell: ({ getValue }) => {
          const countOfItems = getValue().length;
          const firstItem = getValue()[0];
          if (!firstItem) return null;
          return (
            <Flex alignItems="center" justifyContent="space-between">
              <Text as="span" mr="4px">{firstItem.buyer}</Text>
              {countOfItems > 1 && (
                <Tag size="md" variant='subtle' colorScheme='brand'>
                  <TagLabel>+{countOfItems - 1}</TagLabel>
                </Tag>
              )}
            </Flex>
          )
        },
        header: t('subscribers.table.buyer'),
        id: 'buyer',
      }),
      columnHelper.accessor('resources', {
        cell: ({ getValue }) => {
          const countOfItems = getValue().length;
          const firstItem = getValue()[0];
          if (!firstItem) return null;
          return (
            <Flex alignItems="center" justifyContent="space-between">
              <Text as="span" mr="4px">{firstItem.source}</Text>
              {countOfItems > 1 && (
                <Tag size="md" variant='subtle' colorScheme='brand'>
                  <TagLabel>+{countOfItems - 1}</TagLabel>
                </Tag>
              )}
            </Flex>
          )
        },
        header: t('subscribers.table.source'),
        id: 'source',
      }),
      columnHelper.accessor('messages', {
        cell: ({ getValue }) => {
          const countOfItems = getValue().length;
          const firstItem = getValue()[0];
          if (!firstItem) return null;
          return (
            <Box>
              <Flex alignItems="center" mb="5px" justifyContent="space-between" w="100%">
                <Text as="span" mr="4px">{formatDateToHuman(firstItem.createdAt)}</Text>
                {countOfItems > 1 && (
                  <Tag size="md" variant='subtle' colorScheme='brand'>
                    <TagLabel>+{countOfItems - 1}</TagLabel>
                  </Tag>
                )}
              </Flex>
              <Text as="span" mr="4px">{firstItem.username}</Text>
            </Box>
          )
        },
        header: t('subscribers.table.messages'),
        id: 'messages',
      }),
      columnHelper.accessor('createdAt', {
        header: t('subscribers.table.createdAt'),
        id: 'createdAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('updatedAt', {
        header: t('subscribers.table.updatedAt'),
        id: 'updatedAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('id', {
        header: '',
        id: 'actions',
        cell: ({ row }) => (
          <MainItemMenu
            data={row.original}
            handleActivate={() => { }}
            handleDeactivate={() => { }}
            // handleActivate={handleActivate}
            // handleDeactivate={handleDeactivate}
            viewLink={PathsE.SHOW_SUBSCRIBER}
            pathsParams={{ subscriberId: row.original.id }}
          />
        ),
        meta: {
          styles: {
            sizes: {
              width: '44px'
            }
          }
        }
      }),
    ] as ColumnDef<SubscribersElementT>[],
    [t]
  );
};
