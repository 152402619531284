import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver } from 'react-hook-form';
import * as Yup from 'yup';
import initDefaultSchema from '../yupConfig';
import { CreateBotsLinkT, CreateChannelsLinkT } from 'shared/api/types/links';

initDefaultSchema();

// Create Bot's Link
export type CreateBotsLinkFormT = CreateBotsLinkT['request'];
export const getCreateBotsLinkValues = (): CreateBotsLinkFormT => ({
  source: '',
  buyer: '',
  fbPixel: '',
  fbToken: '',
  botId: ''
});

const createBotsLinkSchema: Yup.ObjectSchema<CreateBotsLinkFormT> = Yup.object({
  source: Yup.string().required(),
  buyer: Yup.string().required(),
  fbPixel: Yup.string().required(),
  fbToken: Yup.string().required(),
  botId: Yup.string().required()
});

export const createBotsLinkResolver = yupResolver(createBotsLinkSchema) as Resolver<CreateBotsLinkFormT>;

// Create Channel's Link
export type CreateChannelsLinkFormT = CreateChannelsLinkT['request'];
export const getCreateChannelsLinkValues = (): CreateChannelsLinkFormT => ({
  source: '',
  buyer: '',
  fbPixel: '',
  fbToken: '',
  channelId: ''
});

const createChannelsLinkSchema: Yup.ObjectSchema<CreateChannelsLinkFormT> = Yup.object({
  source: Yup.string().required(),
  buyer: Yup.string().required(),
  fbPixel: Yup.string().required(),
  fbToken: Yup.string().required(),
  channelId: Yup.string().required()
});

export const createChannelsLinkResolver = yupResolver(createChannelsLinkSchema) as Resolver<CreateChannelsLinkFormT>;
