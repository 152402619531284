import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetBots } from "shared/api/requests/bots";
import { useGetChannels } from "shared/api/requests/channels";
import CutText from "shared/components/CutText";
import MainItemMenu from "shared/components/MainItemMenu";
import { PathsE } from "shared/routes";
import { FilterField } from "shared/types/filters";
import {formatDateToHuman, truncateString} from 'shared/utils/formattings'
import { LinksElementT } from '../../api/types/links';

export const useGetFilterFields = (): FilterField[] => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      type: 'text',
      name: 'url',
      defaultValue: '',
      placeholder: t('subscriptions.table.url'),
      label: t('subscriptions.table.url'),
      size: "lg",
    },
    {
      type: 'text',
      name: 'source',
      defaultValue: '',
      placeholder: t('subscriptions.table.source'),
      label: t('subscriptions.table.source'),
      size: "lg",
    },
    {
      type: 'async-select',
      isMulti: true,
      name: 'channelIds',
      defaultValue: [],
      placeholder: 'Select channels',
      optionsQuery: useGetChannels as FixMeLater,
      label: t('fields.channel', { count: 0 }),
      size: "lg",
      labelName: 'name',
      valueName: 'id',
    },
    {
      type: 'async-select',
      isMulti: true,
      name: 'botIds',
      defaultValue: [],
      placeholder: 'Select bots',
      optionsQuery: useGetBots as FixMeLater,
      label: t('bots.bots'),
      size: "lg",
      labelName: 'name',
      valueName: 'id',
    },
    {
      type: 'date-range',
      name: 'createdAt',
      defaultValue: [],
      label: t('subscribers.table.createdAt'),
      size: "lg"
    },
  ], [t]);
}

export const useGetGetBreadcrumbs = () => {
  const { t } = useTranslation();
  return [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
  ];
}


export const useGetColumns = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<LinksElementT>();

  return useMemo(
    () => [
      columnHelper.accessor('id', {
        header: t('subscriptions.table.id'),
        id: 'id',
        cell: ({ getValue }) => (
          <CutText fontWeight="700">
            {getValue()}
          </CutText>
        ),
        meta: {
          isSorting: false,
          styles: {
            sizes: {
              width: '90px',
              maxW: '90px',
            }
          }
        }
      }),
      columnHelper.accessor('resource', {
        header: t('subscriptions.table.resource'),
        id: 'resource',
      }),
      columnHelper.accessor('source', {
        header: t('subscriptions.table.source'),
        id: 'source',
      }),
      columnHelper.accessor('buyer', {
        header: t('subscriptions.table.buyer'),
        id: 'buyer',
      }),
      columnHelper.accessor('fbPixel', {
        header: 'fbPixel',
        id: 'fbPixel',
        cell: ({ getValue }) => truncateString(getValue()),
      }),
      columnHelper.accessor('fbToken', {
        header: 'fbToken',
        id: 'name',
        cell: ({ getValue }) => truncateString(getValue()),
      }),
      columnHelper.accessor('createdAt', {
        header: t('subscriptions.table.createdAt'),
        id: 'createdAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('updatedAt', {
        header: t('subscriptions.table.updatedAt'),
        id: 'updatedAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('id', {
        header: '',
        id: 'actions',
        cell: ({ row }) => (
          <MainItemMenu
            data={row.original}
            handleActivate={() => {}}
            handleDeactivate={() => {}}
            // handleActivate={handleActivate}
            // handleDeactivate={handleDeactivate}
            // viewLink={PathsE.SHOW_CHANNEL}
            pathsParams={{ subscriptionId: row.original.id }}
          />
        ),
        meta: {
          styles: {
            sizes: {
              width: '44px'
            }
          }
        }
      }),
    ] as ColumnDef<LinksElementT>[],
    [t]
  );
};

