// Chakra Imports
import {
	Avatar,
	Box,
	Button,
	Flex,
	Icon,
	Image,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text, useColorMode, useColorModeValue
} from '@chakra-ui/react';
// Custom Components
import PropTypes from 'prop-types';
import { ItemContent } from 'shared/components/horizon-ui/menu/ItemContent';
import { SearchBar } from 'shared/components/horizon-ui/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'shared/components/horizon-ui/sidebar/Sidebar';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { FaEthereum } from 'react-icons/fa';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { MdInfoOutline, MdNotificationsNone } from 'react-icons/md';
import useGetAllRoutes from 'shared/hooks/useGetAllRoutes';
import { useAuthContext } from 'shared/contexts/AuthContext';
import { useTranslation } from 'react-i18next';
export default function HeaderLinks(props: { secondary: boolean }) {
	const { t } = useTranslation();
	const { signOut, self } = useAuthContext();
	const { secondary } = props;
	const { colorMode, toggleColorMode } = useColorMode();
	const routes = useGetAllRoutes(true);

	// Chakra Color Mode
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

	if (!self) return;

	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems='center'
			flexDirection='row'
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
		>
			<SidebarResponsive routes={routes} />
			{/* TODO Dark mode */}
			{/* <Button
				variant='no-hover'
				bg='transparent'
				p='0px'
				minW='unset'
				minH='unset'
				h='18px'
				w='max-content'
				onClick={toggleColorMode}>
				<Icon
					me='10px'
					h='18px'
					w='18px'
					color={navbarIcon}
					as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
				/>
			</Button> */}
			<Menu>
				<MenuButton p='0px'>
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color='white'
						name={self.name}
						bg="gray.500"
						size='sm'
						w='32px'
						h='32px'
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p='0px' borderRadius='12px' bg={menuBg} border='none'>
					<Box
						ps='20px'
						pt='16px'
						pb='10px'
						w='100%'
						borderBottom='1px solid'
						borderColor={borderColor}
						fontSize='sm'
						fontWeight='700'
						color={textColor}
					>
						<Text>
							👋&nbsp;
							{t('profile.hey', { name: self.name })}
						</Text>
						<Text>
							{self.email}
						</Text>
					</Box>
					<Flex flexDirection='column' p='10px'>
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color='red.400'
							borderRadius='8px'
							onClick={() => signOut()}
							px='14px'>
							<Text fontSize='sm'>{t('profile.logOut')}</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
