import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CutText from "shared/components/CutText";
import MainItemMenu from "shared/components/MainItemMenu";
import { PathsE } from "shared/routes";
import { FilterField } from "shared/types/filters";
import { formatDateToHuman } from "shared/utils/formattings";
import { ChannelsElementT } from '../../api/types/channels';

export const useGetFilterFields = (): FilterField[] => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      type: 'text',
      name: 'name',
      defaultValue: '',
      placeholder: t('channels.table.name'),
      label: t('channels.table.name'),
      size: "lg",
    },
    {
      type: 'date-range',
      name: 'createdAt',
      defaultValue: [],
      label: t('subscribers.table.createdAt'),
      size: "lg"
    },
  ], [t]);
}

export const useGetGetBreadcrumbs = () => {
  const { t } = useTranslation();
  return [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
  ];
}


export const useGetColumns = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<ChannelsElementT>();

  return useMemo(
    () => [
      columnHelper.accessor('id', {
        header: t('channels.table.id'),
        id: 'id',
        cell: ({ getValue }) => (
          <CutText fontWeight="700">
            {getValue()}
          </CutText>
        ),
        meta: {
          isSorting: false,
          styles: {
            sizes: {
              width: '90px',
              maxW: '90px',
            }
          }
        }
      }),
      columnHelper.accessor('identifier', {
        header: t('channels.table.identifier'),
        id: 'identifier',
      }),
      columnHelper.accessor('name', {
        header: t('channels.table.name'),
        id: 'name',
      }),
      columnHelper.accessor('createdAt', {
        header: t('channels.table.createdAt'),
        id: 'createdAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('updatedAt', {
        header: t('channels.table.updatedAt'),
        id: 'updatedAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('id', {
        header: '',
        id: 'actions',
        cell: ({ row }) => (
          <MainItemMenu
            data={row.original}
            handleActivate={() => {}}
            handleDeactivate={() => {}}
            // handleActivate={handleActivate}
            // handleDeactivate={handleDeactivate}
            viewLink={PathsE.SHOW_CHANNEL}
            pathsParams={{ channelId: row.original.id }}
          />
        ),
        meta: {
          styles: {
            sizes: {
              width: '44px'
            }
          }
        }
      }),
    ] as ColumnDef<ChannelsElementT>[],
    [t]
  );
};

