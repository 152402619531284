import TextField from 'shared/components/form/TextField';
import { HSeparator } from 'shared/components/horizon-ui/separator/Separator';
import { CreateBotsLinkFormT } from '../../form-values/links';
import { useGetBots } from '../../api/requests/bots';
import Field from '../../components/form/Field';
import {useTranslation} from 'react-i18next'

const BotsLinkForm = () => {
  const {t} = useTranslation();
  const fieldProps = {
    mb: '20px',
    w: {
      base: '100%',
      md: '50%',
    },
  };

  return (
    <>
      <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
      <TextField<CreateBotsLinkFormT>
        name="source"
        label={`${t('forms.source')} *`}
        placeholder={t('forms.enterSource')}
        {...fieldProps}
      />
      <TextField<CreateBotsLinkFormT>
        name="buyer"
        label={`${t('forms.buyer')} *`}
        placeholder={t('forms.enterBuyer')}
        {...fieldProps}
      />
      <TextField<CreateBotsLinkFormT>
        name="fbPixel"
        label={`${t('forms.fbPixel')} *`}
        placeholder={t('forms.enterFbPixel')}
        {...fieldProps}
      />
      <TextField<CreateBotsLinkFormT>
        name="fbToken"
        label={`${t('forms.fbToken')} *`}
        placeholder={t('forms.enterFbToken')}
        {...fieldProps}
      />
      <Field
        type="async-select"
        name="botId"
        label={`${t('forms.botId')} *`}
        placeholder={t('forms.selectBotId')}
        optionsQuery={useGetBots as FixMeLater}
        labelName="name"
        valueName="id"
        defaultValue={null}
        {...fieldProps}
      />
    </>
  );
};

export default BotsLinkForm;
