import { useMemo, MouseEvent } from 'react';
import { Center, Flex, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import { Table, flexRender } from '@tanstack/react-table';
import useGetColors from 'shared/hooks/useGetColors';
import SkeletonTable from './SkeletonTable';
import { useNavigate } from 'react-router-dom';
import { ModelFieldsT } from 'shared/api/types/common';
import MatIcon from 'components/MatIcon';
import { useTranslation } from 'react-i18next';

interface Props<TData> {
  table: Table<TData>;
  isLoading: boolean;
  clickable?: boolean;
  data: TData[];
  size?: number;
}

const TableBody = <TData extends UnknownRecord>({
  table,
  isLoading,
  data,
  clickable,
  size
}: Props<TData>) => {
  const { t } = useTranslation();
  const { gray50, gray200, blue800 } = useGetColors();
  const navigate = useNavigate();

  const columls = useMemo(() => table.getAllColumns(), [data]);
  const rows = useMemo(() => table.getRowModel().rows, [data]);
  const handleRedirect = (e: MouseEvent<HTMLTableRowElement>, id: string) => {
    if (!clickable) return;
    const excludedClasses = ['chakra-button', 'material-symbols-outlined'];
    const CHAKRA_MENU_CLASS_NAME = 'chakra-menu__menu-list'
    const elementClasses = ((e.target as FixMeLater).className as string).split(' ');
    if (elementClasses.find(item => excludedClasses.includes(item))) return;
    if ((e.target as FixMeLater).parentElement.className.includes(CHAKRA_MENU_CLASS_NAME)) return;
    if ((e.target as FixMeLater).parentElement.parentElement.className.includes(CHAKRA_MENU_CLASS_NAME)) return;
    navigate(id);
  };

  if (isLoading) return <SkeletonTable<TData> table={table} size={size} />;
  if (!rows.length)
    return (
      <Tbody>
        <Tr
          color={blue800}
          fontWeight="bold"
          cursor={clickable ? "pointer" : "auto"}
          borderBottom="none"
        >
          <Td colSpan={columls.length} h={200}>
            <Center>
              <Flex flexDirection="column">
                <MatIcon size={48} name="sd_card_alert" />
                <chakra.span>{t('general.table.noData')}</chakra.span>
              </Flex>
            </Center>
          </Td>
        </Tr>
      </Tbody>
    );

  return (
    <Tbody>
      {rows.map((row) => (
        <Tr
          key={row.id}
          color={blue800}
          fontWeight="bold"
          cursor={clickable ? "pointer" : "auto"}
          borderBottom="none"
          _hover={{ bg: gray50 }}
          onClick={(e) => handleRedirect(e, (row.original as unknown as ModelFieldsT).id)}
        >
          {row.getVisibleCells().map((cell) => (
            <Td key={cell.id}
              {...cell.column.columnDef?.meta?.styles?.sizes}
              isNumeric={cell.column.columnDef?.meta?.isNumeric}
              py={cell.column.columnDef.id === 'actions' ? '0' : "12px"}
              px={cell.column.columnDef.id === 'actions' ? '0' : "5px"}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          ))}
        </Tr>
      ))}
    </Tbody>
  );
};

export default TableBody;
