import { LayoutsE } from 'shared/routes';

// Chakra imports
import { Box, Center, Flex, useToken } from '@chakra-ui/react';

// Layout components
import Logo from 'components/Logo';
import FooterAuth from 'shared/components/horizon-ui/footer/FooterAuth';
import { Outlet } from 'react-router-dom';
import LanguageSwitcher from '../components/LanguageSwitcher'

const AuthLayout = () => {
  const [authBg] = useToken(
    'colors',
    ['main']
  );

  return (
    <Flex
      bg={authBg}
      float="right"
      minHeight="100vh"
      height="100%"
      position="relative"
      w="100%"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Box textAlign="center" alignItems="center" display="flex" flexDirection="column" mt="4%">
          <Logo isLong />
        </Box>
        <Center height="100%" marginTop="10">
          <Outlet />
        </Center>
        <Box textAlign="center" alignItems="center" display="flex" flexDirection="column" mt="16px">
          <LanguageSwitcher />
        </Box>
      </Box>
      <Box>
        <FooterAuth />
      </Box>
    </Flex>
  );
};

export default AuthLayout;
