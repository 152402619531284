import { Button, Flex, useDisclosure, Text, Box } from '@chakra-ui/react'
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import MatIcon from "components/MatIcon";
import { useMemo, useState } from "react";
import { BotAnswerT, TemplateOptionT } from 'shared/api/types/channels';
import Table from "shared/components/table/Table";
import { formatDateToHuman } from "shared/utils/formattings";
import { useTranslation } from "react-i18next";
import { useCreateBotAnswer } from 'shared/api/requests/channels'
import { useParams } from "react-router-dom";
import { PathsParams, PathsE } from "shared/routes";
import { CreateBotAnswerFormT } from 'shared/form-values/channels';
import BotAnswerModal from './BotAnswerModal';
import CutText from '../../components/CutText'

interface Props {
  values: BotAnswerT[]
  templateOptions: TemplateOptionT[]
}

const BotAnswers = ({ values, templateOptions }: Props) => {
  const { t } = useTranslation();
  const { channelId } = useParams<PathsParams[PathsE.SHOW_CHANNEL]>();
  const columnHelper = createColumnHelper<BotAnswerT>();
  const { isOpen: isOpenBotAnswerModal, onOpen: onOpenBotAnswerModal, onClose: onCloseBotAnswerModal } = useDisclosure();
  const [selectedBotAnswer, setSelectedBotAnswer] = useState<string | null>(null);

  const { mutate: createMutation } = useCreateBotAnswer({
    pathParams: { channelId },
    params: undefined
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor('templateId', {
        header: t('channels.table.templateId'),
        id: 'templateId',
        cell: ({ getValue }) => (
          <CutText fontWeight="700">
            {getValue()}
          </CutText>
        ),
        meta: {
          isSorting: false,
          styles: {
            sizes: {
              maxW: '90px',
            }
          }
        }
      }),
      columnHelper.accessor('sourceId', {
        header: t('channels.table.sourceId'),
        id: 'sourceId',
        cell: ({ getValue }) => (
          <CutText fontWeight="700">
            {getValue()}
          </CutText>
        ),
        meta: {
          isSorting: false,
          styles: {
            sizes: {
              maxW: '90px',
            }
          }
        }
      }),
      columnHelper.accessor('sourceType', {
        header: t('channels.table.sourceType'),
        id: 'sourceType',
      }),
      columnHelper.accessor('type', {
        header: t('channels.table.type'),
        id: 'type',
      }),
      columnHelper.accessor('message', {
        header: t('channels.table.message'),
        id: 'message',
      }),
      columnHelper.accessor('isActive', {
        header: t('channels.table.isActive'),
        id: 'isActive',
        cell: ({ getValue }) => <span>{t(getValue() ? 'yes' : 'no')}</span>
      }),
      columnHelper.accessor('createdAt', {
        header: t('subscribers.table.createdAt'),
        id: 'createdAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
    ],
    [t]
  );

  const handleCloseBotAnswerModal = () => {
    onCloseBotAnswerModal();
    setSelectedBotAnswer(null)
  }

  return (
    <Box mt={12}>
      <Table data={values} columns={columns as ColumnDef<BotAnswerT>[]} />
      <BotAnswerModal
        templateOptions={templateOptions}
        title={selectedBotAnswer ? t('channels.updateBotAnswer') : t('channels.createBotAnswer')}
        onClose={handleCloseBotAnswerModal}
        botAnswer={values?.find(botAnswer => botAnswer.sourceId === selectedBotAnswer)}
        isOpen={isOpenBotAnswerModal}
        onSubmit={(data: CreateBotAnswerFormT) => {
          if (!selectedBotAnswer) {
            createMutation(data, { onSuccess: handleCloseBotAnswerModal });
            return;
          }
        }}
      />
      <Flex justifyContent="end">
        <Button
          mt="16px"
          fontSize="16px"
          color="white"
          backgroundColor="brand.500"
          fontWeight="600"
          _hover={{
            backgroundColor: "brand.400"
          }}
          paddingY="8px"
          display="flex"
          borderRadius="8px"
          alignItems="center"
          onClick={onOpenBotAnswerModal}
        >
          <MatIcon name="add" size={24} color="gray800" variant="rounded" />
          <Text ml="8px">{t('channels.createBotAnswer')}</Text>
        </Button>
      </Flex>
    </Box>
  )
}

export default BotAnswers;
