import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver } from 'react-hook-form';
import {
  CreateChannelTemplateT,
  CreateChannelT,
  OneChannelT,
  UpdateChannelT,
  TemplateT,
  ChannelTypeT,
  ByttonType,
  CreateBotAnswerT, BotAnswerT, SourceType, BotAnswerType
} from 'shared/api/types/channels'
import * as Yup from 'yup';
import initDefaultSchema from '../yupConfig';

initDefaultSchema();

const channelTypes: ChannelTypeT[] = ['push', 'bonus', 'message', 'default', 'welcome'];

const buttonTypes: ByttonType[] = ['inlineKeyboard', 'keyboard'];

const sourceTypes: SourceType[] = ['channel', 'bot'];

const botAnswerTypes: BotAnswerType[] = ['message', 'default'];

export const channelTypeOptions = channelTypes.map((type) => ({
  value: type,
  label: type,
}))

export const buttonTypeOptions = buttonTypes.map((type) => ({
  value: type,
  label: type,
}))

export const sourceTypeOptions = sourceTypes.map((type) => ({
  value: type,
  label: type,
}))

export const botAnswerTypeOptions = botAnswerTypes.map((type) => ({
  value: type,
  label: type,
}))

// Create Template
export type CreateTemplateFormT = ArrayElement<CreateChannelTemplateT['request']>;
export const getCreateChannelTemplateValues = (values?: TemplateT): CreateTemplateFormT => ({
  afterTime: values?.afterTime ?? 0,
  message: values?.message ?? '',
  type: values?.type ?? 'push',
  buttons: values?.buttons ?? [],
});

const createTemplateSchema: Yup.ObjectSchema<CreateTemplateFormT> = Yup.object({
  afterTime: Yup.number().required(),
  message: Yup.string().required(),
  type: Yup.string().required(),
  buttons: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.string().required("Button label is required"),
      })
    ) as FixMeLater
});
export const createTemplateResolver = yupResolver(createTemplateSchema) as Resolver<CreateTemplateFormT>;

export type CreateChannelFormT = CreateChannelT['request'];
export const getCreateChannelValues = (): CreateChannelFormT => ({
  name: '',
});
const createChannelSchema: Yup.ObjectSchema<CreateChannelFormT> = Yup.object({
  name: Yup.string().required(),
});
export const createChannelResolver = yupResolver(createChannelSchema) as Resolver<CreateChannelFormT>;


// Create Bot Answer

export type CreateBotAnswerFormT = CreateBotAnswerT['request'];
export const getCreateBotAnswerValues = (values?: BotAnswerT): CreateBotAnswerFormT => ({
  templateId: values?.templateId ?? '',
  sourceId: values?.sourceId ?? '',
  sourceType: values?.sourceType ?? 'channel',
  type: values?.type ?? 'default',
  message: values?.message ?? '',
  isActive: values?.isActive ?? false
});

const createBotAnswerSchema: Yup.ObjectSchema<CreateBotAnswerFormT> = Yup.object({
  templateId: Yup.string().required(),
  sourceId: Yup.string().required(),
  sourceType: Yup.string().required(),
  type: Yup.string().required(),
  message: Yup.string().required(),
  isActive: Yup.boolean().required(),
});
export const createBotAnswerResolver = yupResolver(createBotAnswerSchema) as Resolver<CreateBotAnswerFormT>;


// Update Channel
export type UpdateChannelFormT = UpdateChannelT['request'];
export const getUpdateChannelValues = (values?: OneChannelT['response']): UpdateChannelFormT => ({
  name: values?.name ?? '',
});
const updateChannelSchema: Yup.ObjectSchema<UpdateChannelFormT> = Yup.object({
  name: Yup.string().required(),
});
export const updateChannelResolver = yupResolver(updateChannelSchema) as Resolver<UpdateChannelFormT>;
