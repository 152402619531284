import {Box, Button, Flex, Heading, Text} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/horizon-ui/card/Card';
import InfiniteTable from 'shared/components/table/InfiniteTable';
import useGetColors from 'shared/hooks/useGetColors';
import PageTitle from '../admin/PageTitle';
import { useGetColumns, useGetFilterFields, useGetGetBreadcrumbs } from './constants';
import { ChannelsT } from '../../api/types/channels';
import { useGetChannels } from '../../api/requests/channels';
import Link from '../../components/Link'
import {LayoutsE, PathsE} from '../../routes'
import MatIcon from '../../../components/MatIcon'

const Index = () => {
  const { t } = useTranslation();
  const { blue800 } = useGetColors();
  const columns = useGetColumns();
  const breadcrumbs = useGetGetBreadcrumbs();
  const filterFields = useGetFilterFields();

  return (
    <Box>
      <PageTitle title={t('channels.channels')} breadcrumbs={breadcrumbs} />
      <Card mb="20px" borderRadius="20px" px="30px">
        <Heading size="md" marginBottom={5} color={blue800}>
          {t('channels.channelsTable')}
        </Heading>
        <InfiniteTable<ChannelsT>
          query={useGetChannels}
          columns={columns}
          filterFields={filterFields}
          clickable
        />
      </Card>
    </Box>
  );
};

export default Index;
